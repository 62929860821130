import { BusinessRulesAdvancedSearch, BusinessRulesEntryPoint, syncTranslations } from '@driscollsinc/ggs-business-rules-module-ui';
import { Breadcrumbs, Typography } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import { setPageTitleAction, showToast } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import { BusinessRuleActions } from 'constants/BusinessRuleActions';
import { ROUTES, getRoute } from 'constants/routes';
import { urls } from 'constants/urls';
import { designColors } from 'data/constants';
import { EventConstants } from 'data/EventConstants';
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';
import useBusinessRules from 'hooks/v2/useBusinessRules';
import useGAAnalytics from 'hooks/v2/useGAAnalytics';
import useLang from 'hooks/v2/useLang';
import { NAMESPACE } from 'i18n';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { withRouter } from 'routes/withRouter';
import DuExcelUtilities from 'utils/excelUtility';
import { getLanguage, updateBusinessTabCount, userBusinessUnit } from 'utils/helper';
import { ISelectedItems, ISelectItem } from '../BusinessRulesApproval/BusinessRulesApprovalView';
import { IBusinessRulesAdvancedSearchProps, IAdvancedSearchSelectedItems } from 'types/BusinesRuleType';
import useBusinessRuleSearch from 'hooks/v2/useBusinessRuleSearch';
import { Description } from '@mui/icons-material';

function BusinessRules(props) {
    const { logEvent, logError } = useGAAnalytics();

    const navigate = useNavigate();
    const language = localStorage.getItem('GGS_UserLang');
    const [searchParams] = useSearchParams();
    const itemsPerPage = parseInt(localStorage.getItem('GGS_BRsPerPage') || '25');
    const selectedTabURI = searchParams.get(urls.SELECTED_TAB);
    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [businessRulesList, setBusinessRulesList] = useState([]);
    const [selectedRuleId, setSelectedRuleId] = useState(selectedTabURI || '1');
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [serachKeyword, setSerachKeyword] = useState('');
    const [loadingMessage, setLoadingMessage] = useState<string>('Loading');
    const { getTextTranslated } = useLang();

    const [advancedSearchData, setAdvanceSearchData] = useState<IBusinessRulesAdvancedSearchProps>({
        isRanchLoading: false,
        open: false,
        producingAreaList: [],
        producingAreaSelectedList: [],
        growerList: [],
        growerSelectedList: [],
        ranchList: [],
        ranchSelectedList: [],
        berryTypeList: [],
        berryTypeSelectedList: [],
        itemList: [],
        itemSelectedList: [],
        statusList: [],
        statusSelectedList: [],
        onUpdate: (ranches: ISelectItem[], items: ISelectItem[]) => {},
        onChange: (selectedItem: ISelectedItems) => {},
        onCommitChangeOnClose: (type: string, value: ISelectedItems) => {},
        onClose: () => {}
    });

    const {
        submitRule,
        typesError,
        ruleCreateResponse,
        ruleCreateError,
        typesLoading: tabsLoading,
        getBusinessRuleTypes,
        businessRuleTypes,
        getBusinessRules,
        rulesLoading,
        businessRules,
        responseTime,
        businessRulesTotalCount,
        rulesError,
        submitBulkRules
    } = useBusinessRules(props.oktaAuth);

    const {
        isLoading,
        loadingError,
        producingArea,
        grower,
        ranch,
        item,
        status,
        getSearchFieldsOptions,
        getGrower,
        getRanch,
        getBerryTypes,
        getItem,
        berryTypes
    } = useBusinessRuleSearch(props.oktaAuth);

    useEffect(() => {
        syncTranslations(getLanguage(language));
    }, [language]);

    useEffect(() => {
        setTotalItemsCount(businessRulesTotalCount);
    }, [businessRulesTotalCount]);

    useEffect(() => {
        setAdvanceSearchData((prev) => ({
            ...prev,
            producingAreaList: producingArea,
            growerList: grower,
            ranchList: ranch,
            itemList: item,
            berryTypeList: berryTypes,
            statusList: status
        }));
    }, [producingArea, grower, ranch, item, berryTypes, status]);

    useEffect(() => {
        if (businessRules && businessRules.length > 0) {
            const formatedBusinessRuleList = businessRules.map((item) => {
                return {
                    id: item.RuleId,
                    ruleName: item.RuleName,
                    description: `${item.RuleId} - ${item.RuleName}`,
                    rate: item?.Rate,
                    start_date: item.StartDate ? item.StartDate?.split('T')[0] : '',
                    end_date: item.EndDate ? item.EndDate?.split('T')[0] : '',
                    status: item.Status,
                    ruleType: item.RuleTypeId + '',
                    pricingPoolId: item.PricingPoolId,
                    comment: item.Reason,
                    createdBy: item.CreatedBy,
                    createdDateTime: item.CreatedDateTime ? DuDateUtilities.FormatDateFromIso(item.CreatedDateTime) : null,
                    modifiedBy: item.ModifiedBy,
                    modifiedDateTime: item.ModifiedDateTime ? DuDateUtilities.FormatDateFromIso(item.ModifiedDateTime) : null,
                    approver: item.Approver,
                    approvedDateTime: item.ApprovedDateTime ? DuDateUtilities.FormatDateFromIso(item.ApprovedDateTime) : null
                };
            });
            setBusinessRulesList(formatedBusinessRuleList);
        } else {
            setBusinessRulesList([]);
        }
    }, [businessRules]);

    useEffect(() => {
        if (!tabsLoading && selectedRuleId) {
            getBusinessRules(selectedRuleId, itemsPerPage, pageNo, serachKeyword);
        }
    }, [pageNo, serachKeyword, searchParams]);

    useEffect(() => {
        const businessRuleTypeIdsToRemove = [0];
        if (businessRuleTypes && businessRuleTypes.length > 0) {
            const tabList = (businessRuleTypes as any[])
                .filter((item) => !businessRuleTypeIdsToRemove.includes(item.RuleTypeId))
                .map((item) => {
                    return {
                        ...item,
                        value: item.RuleTypeId,
                        label: item.RuleType,
                        count: item.RuleCount,
                        description: item.Description
                    };
                });
            setTabs([...tabList]);
            if (tabList.length > 0) {
                const selectedHere = selectedTabURI || tabList[0].value;
                setSelectedRuleId(selectedHere);
                const index: number = tabList.findIndex((item) => {
                    return item.value === parseInt(selectedRuleId);
                });
                setSelectedTab(index || 0);
            }
        }
    }, [businessRuleTypes]);

    useEffect(() => {
        getBusinessRuleTypes();
    }, []);

    useEffect(() => {
        if (ruleCreateResponse) {
            props.showToast(getTextTranslated('Business Rule deleted successfully', NAMESPACE.BUSINESSRULES), true);
        }
    }, [ruleCreateResponse]);

    useEffect(() => {
        if (ruleCreateResponse && selectedRuleId && pageNo) {
            getBusinessRules(selectedRuleId, itemsPerPage, pageNo, serachKeyword);
        }
        if (ruleCreateError) {
            props.showToast(ruleCreateError?.Message || getTextTranslated('Error Occured while deleting rule', NAMESPACE.BUSINESSRULES), false);
        }
    }, [ruleCreateError, ruleCreateResponse]);

    const onTabChange = (index: number, value?: string, label?: string) => {
        if (value) {
            logEvent(EventConstants.BUSINESS_RULE_TAB_CHANGE, { selectedTab: label, ruleId: value });
            setSerachKeyword('');
            setPageNo(1);
            setSelectedRuleId(value);
            setSelectedTab(index);
            navigate(getRoute(ROUTES.BUSINESS_RULES_LIST, value));
            setLoadingMessage(`Loading ${label}...`);
        }
    };

    const onPageChange = (pageNo: number) => {
        if (pageNo) {
            setPageNo(pageNo);
        }
    };

    const addNewRule = () => {
        logEvent(EventConstants.BUSINESS_RULE_ADD_NEW_RULE, { RuleId: selectedRuleId });
        navigate(getRoute(ROUTES.BUSINESS_RULES_CREATE, selectedRuleId));
    };

    const onEdit = (id) => {
        logEvent(EventConstants.BUSINESS_RULE_EDIT, { RuleId: id });
        navigate(getRoute(ROUTES.BUSINESS_RULES_EDIT, id));
    };

    const onSearch = (keyword) => {
        logEvent(EventConstants.BUSINESS_RULE_SEARCH, { search: keyword });
        setSerachKeyword(keyword);
    };

    const onCopy = (id) => {
        logEvent(EventConstants.BUSINESS_RULE_COPY, { RuleId: id });
        navigate(getRoute(ROUTES.BUSINESS_RULES_COPY, id));
    };

    const onDelete = async (id) => {
        logEvent(EventConstants.BUSINESS_RULE_DELETE, { RuleId: id });
        // Delete rule
        let data = {
            Action: BusinessRuleActions.DELETE,
            BusinessRule: {
                BusinessUnit: userBusinessUnit(),
                RuleId: id,
                RuleTypeId: selectedRuleId
            }
        };
        try {
            const isSuccess = await submitRule(data);
            if (isSuccess) {
                updateBusinessTabCount(tabs, selectedRuleId);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleExcelFile = async (data, stats) => {
        if (data === null) {
            return;
        }

        if ((data.SheetNames || []).length === 1) {
            handleExcelSheet(data, data.SheetNames[0], stats);
        } else {
            //open a dialog to let user choose
        }
    };

    const handleExcelSheet = async (data, sheetName, stats) => {
        const excelData = await DuExcelUtilities.ReadRows(data, sheetName, null, { defval: '', raw: false });
        submitBulkRules(excelData);
    };

    const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        DuExcelUtilities.HandleFiles(event.currentTarget.files, handleExcelFile, () => {}, { type: 'array' });
    };

    const showAdvancedSearch = () => {
        getSearchFieldsOptions();
        setAdvanceSearchData((prev) => ({ ...prev, open: true }));
    };

    const onAdvancedSearch = (selectedItem: IAdvancedSearchSelectedItems) => {
        const payload = {
            ...selectedItem,
            producingArea: selectedItem.producingArea.map((item) => {
                return item.value;
            }),
            grower: selectedItem.grower.map((item) => {
                return item.value;
            }),
            ranch: selectedItem.ranch.map((item) => {
                return item.value;
            }),
            item: selectedItem.item.map((item) => {
                return item.value;
            }),
            status: selectedItem.status.map((item) => {
                return item.value;
            }),
            berryType: selectedItem.berryType.map((item) => {
                return item.value;
            })
        };

        console.log(payload);
    };

    const getNavPath = (route, ...args) => {
        return getRoute(route, ...args);
    };

    const changeDropDown = (selectedItems: IAdvancedSearchSelectedItems) => {
        const updatedField = selectedItems.updatedField;
        if (updatedField === 'producingArea') {
            getGrower(
                selectedItems.producingArea.map((item) => {
                    return item.value;
                })
            );
            getRanch(selectedItems);
        } else if (updatedField === 'grower') {
            getRanch(selectedItems);
            getBerryTypes(selectedItems);
        } else if (updatedField === 'ranch') {
            getBerryTypes(selectedItems);
        } else if (updatedField === 'berryType') {
            getItem(selectedItems);
        }
    };

    return (
        <DrcWrapper>
            {props.userIsAdmin && <Navigate to={getRoute(ROUTES.BUSINESS_RULES_APPROVE)} />}
            <div style={{ padding: '0 20px', height: '100%' }}>
                <BusinessRulesEntryPoint
                    title={'Business Rules'}
                    breadcrumbs={
                        <Breadcrumbs sx={{ color: 'black', '@media (prefers-color-scheme: dark)': { color: `#a7a7a7 !important` } }}>
                            <Typography sx={{ color: designColors.blue, '@media (prefers-color-scheme: dark)': { color: 'white' } }} fontWeight={500}>
                                Home
                            </Typography>
                            <Typography>Business Rules</Typography>
                        </Breadcrumbs>
                    }
                    responseTime={responseTime}
                    showUploadButton={window.config.SHOW_BUSINESS_RULE_UPLOAD_BITTON === 'true'}
                    errorMessage={typesError || rulesError}
                    currentPage={pageNo}
                    tabs={tabs}
                    selectedTab={selectedTab}
                    displayTabsCount={5}
                    isLoading={tabsLoading}
                    isTableLoading={rulesLoading}
                    data={businessRulesList}
                    totalItems={isNaN(totalItemsCount) || totalItemsCount <= 0 ? 1 : totalItemsCount}
                    itemsPerPage={itemsPerPage}
                    onTabChange={onTabChange}
                    onPageChange={onPageChange}
                    addNewRule={addNewRule}
                    onEdit={onEdit}
                    onCopy={onCopy}
                    onDelete={onDelete}
                    noRowMessage={'No data found'}
                    onSearch={onSearch}
                    loadingText={loadingMessage}
                    loadingNameSpace="common"
                    onUpload={onUpload}
                    showAdvancedSearch={showAdvancedSearch}
                    getRoute={getNavPath}
                    copyRoute={ROUTES.BUSINESS_RULES_COPY}
                    editRoute={ROUTES.BUSINESS_RULES_EDIT}
                />
            </div>
            <BusinessRulesAdvancedSearch
                {...advancedSearchData}
                isRanchLoading={isLoading}
                onSearch={(selectedItem: IAdvancedSearchSelectedItems) => {
                    onAdvancedSearch(selectedItem);
                }}
                onChange={(selectedItem: IAdvancedSearchSelectedItems) => {
                    changeDropDown(selectedItem);
                }}
                onCommitChangeOnClose={(type: string, value: IAdvancedSearchSelectedItems) => {
                    console.log('hghhh');
                }}
                onClose={() => {
                    setAdvanceSearchData((prev) => ({ ...prev, open: false }));
                }}
            />
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer, masterReducer }) => ({
    userPreference: masterReducer.userPreference,
    userIsAdmin: rootReducer.userIsAdmin,
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(BusinessRules)));
