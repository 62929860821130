import { Call } from '@driscollsinc/driscolls-display-rules';
import { APPROVER_GROUP_ID } from 'data/constants';
import { useState } from 'react';
import APIEndPoints from 'services/api';
import { Store } from 'data/store';
import {
    GET_BUSINESS_RULES_FOR_APPROVER,
    GET_BUSINESS_RULE_TYPES,
    GET_BUSINESS_RULES,
    GET_BUSINESS_RULES_HISTORY,
    GET_LOOKUP_DATA,
    GET_RULES_DETAILS,
    POST_SUBMIT_RULE,
    POST_ASSIGNABLE_LIST,
    GET_ACTIVE_PRICING_POOLS
} from 'services/v2/BusinessRulesAPI';
import { getCall, postCall, SUCCESS_STATUS_CODE } from 'services/v2/Services';
import useGetApiTokens from './useGetApiTokens';
import { userBusinessUnit } from 'utils/helper';
import useGAAnalytics from './useGAAnalytics';
import { EventConstants } from 'data/EventConstants';
import { IAdvancedSearchSelectedItems, IAssignableCriteriaList, IAssignableSummary, IBusinesRuleType } from 'types/BusinesRuleType';
import { businssRulePayload } from 'data/BusinessRules';
import { ISelectedItems } from 'pages/v2/BusinessRulesApproval/BusinessRulesApprovalView';

const LOOKUP_TYPE_BRAND: string = 'Brand';
const LOOKUP_TYPE_BERRYTYPE: string = 'BerryType';
const LOOKUP_TYPE_FIELDTYPE: string = 'FieldType';

const useBusinessRuleSearch = (oktaAuth: any) => {
    const { logEvent, logError } = useGAAnalytics();
    const { getTokenAndId } = useGetApiTokens();
    const [producingArea, setProducingArea] = useState([] as any[]);
    const [grower, setGrower] = useState([] as any[]);
    const [ranch, setRanch] = useState([] as any[]);
    const [item, setItem] = useState([] as any[]);
    const [berryTypes, setBerryTypes] = useState([] as any[]);
    const [status, setStatus] = useState([
        { label: 'ACTIVE', value: 'active' },
        { label: 'NEW', value: 'new' },
        { label: 'MODIFIED', value: 'modified' },
        { label: 'PENDING APPROVAL', value: 'pending approval' },
        { label: 'REJECTED', value: 'rejected' }
    ]);
    const [assignmentListForItems, setAssignmentListForItems] = useState<IAssignableCriteriaList>();
    const [loadingError, setLoadingError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [responseTime, setResponseTime] = useState<number>(0);

    const getUniqueListBy = (arr: any, key: string) => {
        return [...new Map(arr.map((item) => [item[key], item])).values()];
    };

    const getGrower = (ProducingAreaCodes: any[]) => {
        const sourceDataList: IAssignableCriteriaList = assignmentListForItems;
        let growers = getUniqueListBy(
            (sourceDataList?.AssignableRanchList || [])
                .map((item) => {
                    return {
                        label: item.GrowerNbr + ' - ' + item.GrowerName,
                        value: item.GrowerNbr,
                        GrowerNbr: item.GrowerNbr,
                        GrowerName: item.GrowerName,
                        ProducingAreaCode: item.ProducingAreaCode,
                        FieldType: item.FieldType,
                        BerryType: item.BerryType
                    };
                })
                .filter((item) => {
                    return ProducingAreaCodes?.includes(item.ProducingAreaCode);
                }),
            'value'
        );
        setGrower(growers);
    };

    const getRanch = async (data: IAdvancedSearchSelectedItems) => {
        const sourceDataList: IAssignableCriteriaList = assignmentListForItems;
        let ranches = getUniqueListBy(
            (sourceDataList?.AssignableRanchList || [])
                .map((item) => {
                    return {
                        label: item.RanchNbr + ' - ' + item.RanchName,
                        value: item.RanchNbr,
                        FieldType: item.FieldType,
                        BerryType: item.BerryType,
                        RanchNbr: item.RanchNbr,
                        RanchName: item.RanchName,
                        GrowerName: item.GrowerName,
                        GrowerNbr: item.GrowerNbr,
                        ProducingAreaCode: item.ProducingAreaCode
                    };
                })
                .filter((item) => {
                    return (
                        (data.producingArea?.length > 0
                            ? data.producingArea
                                  .map((item) => {
                                      return item.value;
                                  })
                                  .includes(item.ProducingAreaCode)
                            : true) &&
                        (data.grower?.length > 0
                            ? data.grower
                                  .map((item) => {
                                      return item.value;
                                  })
                                  .includes(item.GrowerNbr)
                            : true)
                    );
                }),
            'value'
        );
        setRanch(ranches);
    };

    const getBerryTypes = (data: IAdvancedSearchSelectedItems) => {
        const berryTypeList = getUniqueListBy(
            (assignmentListForItems.AssignableRanchList || [])
                .map((item) => {
                    return data.ranch
                        .map((item2) => {
                            return item2.value;
                        })
                        .includes(item.RanchNbr)
                        ? { label: item.BerryType, value: item.BerryType }
                        : null;
                })
                .filter((item3) => {
                    return item3 != null;
                }),
            'value'
        );
        setBerryTypes(berryTypeList);
    };

    const getItem = async (data: IAdvancedSearchSelectedItems) => {
        const sourceDataList: IAssignableCriteriaList = assignmentListForItems;
        let items: any[] = (sourceDataList?.AssignableItemList || [])
            .map((item) => {
                return {
                    label: item.ItemNbr + ' - ' + item.ItemName,
                    value: item.ItemNbr,
                    ItemName: item.ItemName,
                    ItemNbr: item.ItemNbr,
                    FieldType: item.FieldType,
                    Brand: item.Brand,
                    BerryType: item.BerryType
                };
            })
            .filter((item: any) => {
                return data.berryType.length > 0
                    ? data.berryType
                          .map((bp) => {
                              return bp.value;
                          })
                          ?.includes(item?.BerryType)
                    : true;
            });
        setItem(items);
    };

    const getSearchFieldsOptions = async () => {
        setIsLoading(true);
        const startTime = performance.now();
        const { token } = await getTokenAndId(oktaAuth);
        const state = Store.getState();
        const payload = {
            JSONRequest: {
                Action: 'GET_ASSIGNABLE_LIST',
                BusinessUnit: userBusinessUnit(),
                LanguageCode: state?.masterReducer?.userLang?.toLowerCase()
            }
        };

        try {
            const response = await postCall(POST_ASSIGNABLE_LIST(), token, {}, payload);
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                const data = response.display.Data?.[0];
                setAssignmentListForItems(data);
                const producingArea = getUniqueListBy(
                    (data?.AssignableRanchList || [])
                        .map((item) => {
                            return {
                                label: item.ProducingAreaCode + ' - ' + item.ProducingAreaName,
                                value: item.ProducingAreaCode,
                                ProducingAreaCode: item.ProducingAreaCode,
                                ProducingAreaName: item.ProducingAreaName
                            };
                        })
                        .sort((first, next) => parseInt(first?.ProducingAreaCode) - parseInt(next?.ProducingAreaCode)),
                    'value'
                );

                const ranches = getUniqueListBy(
                    (data.AssignableRanchList || [])
                        .map((item) => {
                            return {
                                label: item.RanchNbr + ' - ' + item.RanchName,
                                value: item.RanchNbr,
                                RanchNbr: item.RanchNbr,
                                RanchName: item.RanchName,
                                GrowerName: item.GrowerName,
                                ProducingAreaCode: item.ProducingAreaCode
                            };
                        })
                        .sort((first, next) => parseInt(first?.RanchNbr) - parseInt(next?.RanchNbr)),
                    'value'
                );

                const growers = getUniqueListBy(
                    (data.AssignableRanchList || [])
                        .map((item) => {
                            return {
                                label: item.GrowerNbr + ' - ' + item.GrowerName,
                                value: item.GrowerNbr,
                                GrowerNbr: item.GrowerNbr,
                                GrowerName: item.GrowerName,
                                ProducingAreaCode: item.ProducingAreaCode
                            };
                        })
                        .sort((first, next) => parseInt(first?.GrowerNbr) - parseInt(next?.GrowerNbr)),
                    'value'
                );

                const items = (data.AssignableItemList || [])
                    .map((item) => {
                        return {
                            label: item.ItemNbr + ' - ' + item.ItemName,
                            value: item.ItemNbr,
                            ItemName: item.ItemName,
                            ItemNbr: item.ItemNbr,
                            FieldType: item.FieldType,
                            Brand: item.Brand,
                            BerryType: item.BerryType
                        };
                    })
                    .sort((first, next) => parseInt(first?.ItemNbr) - parseInt(next?.ItemNbr));

                const berryTypes = getUniqueListBy(
                    items.map((item) => {
                        return { value: item.BerryType || '', label: item.BerryType };
                    }),
                    'value'
                );

                setProducingArea(producingArea);
                setItem(items);
                setRanch(ranches);
                setGrower(growers);
                setBerryTypes(berryTypes);
            }
        } catch (err) {
            setLoadingError(err);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        getSearchFieldsOptions,
        getGrower,
        getRanch,
        getBerryTypes,
        getItem,
        isLoading,
        loadingError,
        item,
        ranch,
        producingArea,
        grower,
        berryTypes,
        status
    };
};
export default useBusinessRuleSearch;
